<script>
import BaseImage from './BaseImage.vue'
export default {
  name: 'Header',
  components: {
    BaseImage
  }
}
</script>

<template>
  <div class="header">
    <b-navbar fixed="top" class="main-nav" toggleable="lg">
      <b-container>
        <b-navbar-brand to="/">
          <BaseImage
            src="img/icon.png"
            height="60px"
            class="d-inline-block"
            alt="ΕΥΑΠΟΔΡΑΣΗ"
          />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" class="main-nav-collapse" is-nav>
          <b-navbar-nav class="main-nav-collapse-navbar">
            <b-nav-item to="/about" exact-active-class="active"
              ><span class="navigation-title">Περιγραφή</span></b-nav-item
            >
            <b-nav-item to="/material" exact-active-class="active"
              ><span class="navigation-title">Υλικό</span></b-nav-item
            >
            <b-nav-item to="/gallery" exact-active-class="active"
              ><span class="navigation-title">Photo Gallery</span></b-nav-item
            >
            <b-nav-item to="/videos" exact-active-class="active"
              ><span class="navigation-title">Videos</span></b-nav-item
            >
            <b-nav-item to="/contact" exact-active-class="active"
              ><span class="navigation-title">Επικοινωνία</span></b-nav-item
            >
            <b-nav-item
              href="https://easyescapeportal.azurewebsites.net/app/games"
              target="_blank"
            >
              <span class="navigation-title">Είσοδος</span>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<style scoped lang="scss">
@use "./../assets/scss/abstracts" as *;

.header {
  .main-nav {
    padding: $s-s 0;
    background-color: $mine-shaft;
  }
}

.navigation-title {
  color: $grey;

  &:hover {
    color: $lightgrey;
  }
}

.main-nav .main-nav-collapse-navbar .main-nav-collapse-nav-item,
.main-nav .nav-item {
  padding: $s-s $s-m;
}

@media only screen and (max-width: 992px) {
  .main-nav .main-nav-collapse-navbar .main-nav-collapse-nav-item,
  .main-nav .nav-item {
    padding: $s-xxs $s-m;
  }
}
</style>
