<script>
export default {
  name: 'BaseImage',
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String
    },
    title: {
      type: String,
      default: 'ΕΥΑΠΟΔΡΑΣΗ'
    },
    loading: {
      // 'auto', 'lazy', 'eager', false
      type: [String, Boolean],
      default: 'lazy',
      validator: function (value) {
        return ['auto', 'lazy', 'eager', false].indexOf(value) !== -1
      }
    }
  }
}
</script>

<template>
  <img :src="src" :alt="alt || title" :loading="loading ? 'lazy' : 'eager'" />
</template>
