<script>
import BaseImage from './BaseImage.vue'
const currentYear = new Date().getFullYear()
const appStoreUrl = 'https://apps.apple.com/gr/app/evapodrasi/id1577752626'
export default {
  name: 'Footer',
  components: {
    BaseImage
  },
  computed: {
    currentYear () {
      return currentYear
    }
  },
  methods: {
    onButtonClicked () {
      this.openAppStorePage()
    },
    openAppStorePage () {
      window.open(appStoreUrl, '_blank')
    }
  }
}
</script>

<template>
  <div class="footer">
    <div class="project-info">
      <div class="project-info-title">iOS Εφαρμογή</div>
       <b-button class="mb-2 ios-button" type="submit" @click="onButtonClicked">
        <div class="ios-button-image-and-text">
          <BaseImage src="img/footer/apple.svg" alt="Apple" />
          <div class="ios-button-texts">
            <p class="ios-button-first-text">Download on the</p>
            <p class="ios-button-second-text">App Store</p>
          </div>
        </div>
      </b-button>
    </div>
    <div class="project-financing">
      <b-container class="bv-example-row">
        <b-row>
          <b-col cols="12">
            <div class="project-financing-title">ΕΥΑΠΟΔΡΑΣΗ</div>
            <a target="_blank" href="pdf/poster.pdf">
              <BaseImage
                class="img-fluid project-financing-image"
                src="img/footer/espa-financing.png"
                alt="Espa Financing"
              />
            </a>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="copyright">
      <p>Copyright © {{ currentYear }} - Easyescape | All Rights Reserved</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use './../assets/scss/abstracts' as *;

.footer {
  background: $black;
  color: $red;
  text-align: center;
}

.project-info {
  background-color: $white;
  padding: $s-xxl 0;
}

.project-info-title {
  font-size: $s-l;
  font-weight: 700;
  color: $red;
  margin-bottom: $s-m;
}

.project-info-image {
  width: $s-xxxl;
}

.project-info-subtitle {
  color: $red;
  font-size: $s-m;
  font-weight: 700;
  margin: $s-m 0;
}

.project-financing {
  background-color: $mine-shaft;
  padding: $s-xl 0;
}

.project-financing-title {
  font-family: "Waiting for the Sunrise", cursive;
  font-size: $s-xl;
  text-align: center;
  margin: $s-s 0 $s-l 0;
}

.project-financing-image {
  width: 700px;
}

.copyright {
  font-size: $s-m;
  margin: $s-m 0;
}

.ios-button {
  background: $black;
  border-radius: $s-l;
  padding: 15px 30px
}

.ios-button-image-and-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.ios-button-texts {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  white-space: nowrap;
}

.ios-button-second-text {
  margin-top: -$s-m;
  font-size: $s-xl;
}
</style>
