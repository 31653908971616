export default [
  {
    path: '/',
    name: 'home',
    component: () => import('./../pages/Home.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('./../pages/About.vue')
  },
  {
    path: '/material',
    name: 'material',
    component: () => import('./../pages/Material.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('./../pages/Contact.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import('./../pages/Gallery.vue')
  },
  {
    path: '/videos',
    name: 'videos',
    component: () => import('./../pages/Videos.vue')
  }
]
